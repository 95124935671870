import { withTimeout } from '@patrianna/shared-utils/promise-utils'
import { SeonChannel, SeonEvent } from '@patrianna-payments/mf-channels'

import { pubSubManager } from 'services/pubsub_con'

const seonChannel = pubSubManager.createActionChannel(SeonChannel.Actions)

const handleSessionId = (eventType: string) =>
  withTimeout(
    10000,
    new Promise<string>((res) => {
      seonChannel.open()

      seonChannel.subscribe((event, payload) => {
        if (event === SeonEvent.GetSessionID) {
          res(payload as string)
        }
      })

      seonChannel.publish(eventType, undefined)
    })
  ).catch(() => {
    console.warn(`Failed to ${eventType}`)

    return ''
  })

export const generateSeonSessionId = () => handleSessionId(SeonEvent.GenerateSessionId)
export const regenerateSeonSessionId = () => handleSessionId(SeonEvent.RegenerateSessionId)
export const clearSeonSessionId = () => handleSessionId(SeonEvent.ClearSessionId)
export const getOneTimeSessionId = () => handleSessionId(SeonEvent.GetOneTimeSessionId)
